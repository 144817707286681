<template>
  <div class="chart">
    <div class="head"><zq-index-picker v-model="selected"/></div>
    <div class="body" v-if="currentSymbol.symbol">
      <kline-chart v-show="selected.period[0] !== 'min'" :symbol="currentSymbol.symbol" :zq="selected.period[0]" :overlay="selected.indexes[0]" :oscillator="selected.indexes[1]" />
      <line-chart v-show="selected.period[0] === 'min'" :symbol="currentSymbol.symbol" :index="selected.indexes[2]" />
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { inject, ref } from 'vue'
import ZqIndexPicker from '@/components/ZqIndexPicker.vue'
import LineChart from './LineChart.vue'
import KlineChart from './KlineChart.vue'

export default {
  name: 'Chart',
  components: {
    ZqIndexPicker, LineChart, KlineChart
  },
  setup() {
    const currentSymbol = inject('currentSymbol', { symbol: null })
    return {
      currentSymbol,
      selected: ref({ period: ['day', '日线'], indexes: ['BBI', 'MACD', 'MACD'] })
    }
  }
}
</script>

<style scoped lang="scss">
.chart {
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  .body {
    flex-grow: 1;
    overflow: hidden;
  }
}
</style>
