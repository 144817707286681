<template>
<div class="weui-cell">
  <div class="weui-cell__bd picker" @click="showPicker">{{currentMarket.name}} <i>▾</i></div>
</div>
</template>

<script>
import { defineComponent, inject, ref } from 'vue'
import weui from 'weui.js'
import axios from 'axios'
import jsonp from 'axios-jsonp'

export default defineComponent({
  setup() {
    const markets = ref([])
    const currentMarket = inject('currentMarket', { name: '' })

    axios({
      url: 'http://m.ytcj.com:8080/action/market/list',
      adapter: jsonp
    }).then(({ data }) => {
      markets.value = data
      currentMarket.value = data[0]
    })

    return {
      markets,
      currentMarket,
      showPicker() {
        if (currentMarket.value.id) {
          weui.picker(
            markets.value.map(({ id, name }) => ({ label: name, value: id })),
            {
              id: 'market',
              defaultValue: [currentMarket.value.id],
              onConfirm(result) {
                currentMarket.value = markets.value.find(m => m.id === result[0].value)
              },
              title: '选择市场'
            }
          )
        }
      }
    }    
  }
})
</script>

<style scoped lang="scss">
.picker {
  text-align: center;

  i {
    font-style: normal;
    color: #888;
  }
}
</style>
