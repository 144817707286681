import _ from 'lodash'
import { ref } from 'vue'
import io from 'socket.io-client'

const socket = io.connect('http://m.ytcj.com:8081')
const quotations = ref([])
const fields = ref([])
const symbols = ref([])

function registerSymbols({ symbols: rsymbols, fields: rfields }) {
  quotations.value = []
  symbols.value = rsymbols

  fields.value = rfields || ['price', 'last_settle']
  socket.emit('register fields', fields.value)

  //if (symbols.indexOf('SH1A0001') < 0) symbols.push('SH1A0001')
  //if (symbols.indexOf('SZ399001') < 0) symbols.push('SZ399001')

  socket.emit('register symbols', symbols.value)
}

const init = _.once(function () {
  socket.on('quotation', data => {
    if (quotations.value.length > 0) {
      data.forEach(v => {
        let cnt, quo = quotations.value[v[0]];

        for (let i = 1; i < v.length; i++) {
            let field = fields.value[i - 1];
            switch (field) {
                case 'buys':
                case 'sells':
                    cnt = 0;
                    v[i].forEach(ss => {
                        quo[field][cnt] += cnt % 2 ? ss : ss / Math.pow(10, quo.digits);
                        ++cnt;
                    });
                    break;
                case 'details':
                    cnt = 0;
                    v[i].forEach(ss => {
                        quo[field][cnt] += (cnt % 4) !== 1 ? ss : ss / Math.pow(10, quo.digits);
                        ++cnt;
                    });
                    break;
                case 'volume':
                case 'amount':
                    quo[field] += v[i];
                    break;
                case 'price':
                    if (v[i]) quo.lastModified = new Date();
                    quo.updown = v[i];
                    quo[field] += v[i] / Math.pow(10, quo.digits);
                    break;
                default:
                    quo[field] += v[i] / Math.pow(10, quo.digits);
            }
        }

        if (quo.last_settle && quo.price) {
          quo.fluctuation = quo.price - quo.last_settle;
          quo.fluctuationRate = parseFloat((quo.fluctuation / quo.last_settle).toFixed(4));
        } else {
          delete quo.fluctuation;
          delete quo.fluctuationRate;
        }
      });

      quotations.value.forEach(q => {
        if (q && new Date() - q.lastModified > 2000) q.updown = 0;
      });
    } else {
      data.forEach(v => {
        if ('string' !== typeof v[1]) return ;

        var quo = quotations.value[v[0]] = {};
        quo.symbol = symbols.value[v[0]];
        quo.name = v[1];
        quo.digits = v[2];
        for (let i = 3; i < v.length; i++) {
          const field = fields.value[i - 3];
          quo[field] = v[i];
          switch (field) {
            case 'volume':
            case 'amount':
            case 'buys':
            case 'sells':
            case 'details':
              break;
            default:
              quo[field] = quo[field] / Math.pow(10, quo.digits);
          }
        }

        quo.updown = 0;

        if (quo.last_settle && quo.price) {
          quo.fluctuation = quo.price - quo.last_settle;
          quo.fluctuationRate = parseFloat((quo.fluctuation / quo.last_settle).toFixed(4));
        }
      });
    }

    quotations.value.forEach((q, /*idx*/) => {
      if (!q) return;

      function gen(f) {
        if (q[f]) {
          q[f + '_data'] = [];
          for (let i = 0; i < q[f].length; i += 2) {
            if (q[f][i] > 0) {
              q[f + '_data'].push({ price: q[f][i], volume: q[f][i + 1]});
            }
          }
        }
      }

      gen('buys');
      gen('sells');
      if (q.sells_data) q.sells_data.reverse();
      q.details_data = q.details_data || [];
      let tmp = [];
      let clear;
      for (let i = 0; q.details && i < q.details.length - 4; i += 4) {
        if (q.details[i + 2] > (q.last_volume || 0) && q.details[i + 6] > 0) {
          let vol = q.details[i + 2] - q.details[i + 6],
          pos = q.details[i + 3] - q.details[i + 7];

          if (vol < 0) {
            clear = true;
            vol = q.details[i + 2];
            pos = q.details[i + 3];
          }

          tmp.push({
            time: new Date(q.details[i] * 1000),
            price: q.details[i + 1],
            volume: vol,
            position: pos
          });

          if (clear) break;
        }
      }

      q.details_data = clear ? tmp : tmp.concat(q.details_data);
      if (q.details_data.length > 50) q.details_data = q.details_data.slice(0, 50);

      q.last_volume = q.volume;
    });

    quotations.value = [...quotations.value];
  })
})

export {
  quotations,
  fields,
  symbols,
  registerSymbols,
  init
}