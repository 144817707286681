<template>
  <div class="home">
    <div class="left">
      <div class="head"><market-select /></div>
      <div class="body"><quotation-table /></div>
    </div>
    <div :class="{ right: true, hidden: !showChart }">
      <chart />
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { provide, ref } from 'vue'
import MarketSelect from '@/components/MarketSelect.vue'
import QuotationTable from '@/components/QuotationTable.vue'
import Chart from '@/components/Chart.vue'

export default {
  name: 'Home',
  components: {
    MarketSelect, QuotationTable, Chart
  },
  setup() {
    const showChart = ref(false)
    provide('currentMarket', ref({ name: '' }))
    provide('currentSymbol', ref({ name: '' }))
    provide('showChart', showChart)

    return { showChart }
  }
}
</script>

<style scoped lang="scss">
.home {
  height: 100%;
  width: 100%;
  display: flex;
  overflow: hidden;

  >.left {
    width: 320px;
    height: 100%;
    padding: 0 8px;
    display: flex;
    flex-direction: column;

    .body {
      flex-grow: 1;
      overflow-y: auto;
    }
  }

  >.right {
    flex-grow: 1;
    overflow: hidden;
  }

  @media screen and (max-width: 720px) {
    >.left {
      width: 100%;
    }

    >.right {
      background-color: #fff;
      z-index: 999;
      flex-grow: 0;
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;

      &.hidden {
        visibility: hidden;
      }
    }
  }
}
</style>
