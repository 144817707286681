<template>
  <div ref="container" />
</template>

<script>
import ResizeSensor from 'css-element-queries/src/ResizeSensor'
import { defineComponent, onMounted, watch, ref } from 'vue'

import HQChart from 'hqchart'
HQChart.Chart.JSConsole.Chart.Log = () => undefined
HQChart.Chart.JSConsole.Complier.Log = () => undefined

import { getHistoryData } from '@/api/chartData'

async function getKlineData(data, callback) {
  data.PreventDefault = true
  // console.log(data)
  switch(data.Name) {
  case 'MinuteChartContainer::RequestMinuteData':   //分钟全量数据下载
      data = await getHistoryData(data.Request.Data.symbol[0], 'min')
      callback(data)
      break;
  case 'MinuteChartContainer::RequestMinuteRealtimeData':  //分钟实时数据更新
      break;
  }
}

export default defineComponent({
  props: {
    symbol: String,
    index: String
  },
  setup(props) {
    let chart
    const container = ref()

    onMounted(() => {
      new ResizeSensor(container.value.parentNode, function() {
        if (chart) {
          container.value.style.width = container.value.parentNode.clientWidth + 'px'
          container.value.style.height = container.value.parentNode.clientHeight + 'px'
          chart.OnSize()
        }
      })

      container.value.style.width = container.value.parentNode.clientWidth + 'px'
      container.value.style.height = container.value.parentNode.clientHeight + 'px'
      chart = HQChart.Chart.JSChart.Init(container.value)

      chart.SetOption({
        Type: '分钟走势图', //历史分钟走势图
        Symbol: props.symbol + '.FOREX',
        NetworkFilter: getKlineData,
        // Symbol: '600000.sh',
        IsAutoUpdate: true, //是自动更新数据
        AutoUpdateFrequency: 1000,
        Windows: [
          { Index: props.index }
        ],
        DayCount: 1,
        IsShowRightMenu: false,         //右键菜单
        IsShowCorssCursorInfo: true,    //是否显示十字光标的刻度信息
        Border: {
          Left: 60, //左边间距
          Right: 60, //右边间距
          Top: 25,
          Bottom: 20
        },
        KLineTitle: {
          IsShowName: false, //不显示股票名称
          IsShowSettingInfo: false //不显示周期/复权
        },
        Frame: [
          { SplitCount: 5, StringFormat: 0 },
          { SplitCount: 5, StringFormat: 0 },
          { SplitCount: 5, StringFormat: 0 }
        ]
      })

      const { OnTouchEnd } = chart.JSChartContainer;
      chart.JSChartContainer.OnTouchEnd = function (e) {
        OnTouchEnd.call(this, e)
        this.LastPoint.X = null
        this.LastPoint.Y = null
        this.Draw()
      }
    })

    watch(() => ([props.symbol, props.index]), async ([symbol, index], [oldSymbol, oldIndex]) => {
      if (symbol !== oldSymbol) {
        chart.ChangeSymbol(symbol + '.FOREX')
      }

      if (index !== oldIndex) {
        // console.log(index)
        chart.ChangeIndex(1, index)
      }
    })

    return { container }
  }
})
</script>
