<template>
  <router-view />
</template>

<style lang="scss">
html, body, #app {
  width: 100%;
  height: 100%;
  user-select: none;
}

.weui-half-screen-dialog.weui-picker {
  max-width: 420px;
  margin: 0 auto;
}
</style>
