// const { Chart: { JSConsole } } = require('hqchart')
import _ from 'lodash'
import HQChart from 'hqchart'
import Bezier from 'bezier-easing'
const { Chart: { JSConsole/*, IFrameSplitOperator */ } } = HQChart

const JSCHART_DRAG_ID = {
  DISABLE_DRAG_ID: 0,
  CLICK_TOUCH_MODE_ID: 3, //长按十字光标显示保留/点击十字光标消失 (使用TouchStatus)
}

// function TooltipData() {
//   //提示信息
//   this.ChartPaint;
//   this.Data;
//   this.Type = 0;
// }

export default function(chart) {
  const { OnTouchStart, OnMouseMove, OnTouchEnd } = chart.JSChartContainer

  chart.JSChartContainer.Draw = _.throttle(chart.JSChartContainer.Draw, 60)

  chart.JSChartContainer.recordDrag = function() {
    this.MouseDrag.history = this.MouseDrag.history || []
    this.MouseDrag.history.push({ ...this.MouseDrag.LastMove, ts: Date.now() })
    if (this.MouseDrag.history.length > 5) this.MouseDrag.history.shift()
  }

  chart.JSChartContainer.cancelAnimation = function () {
    if (this.animationTimer) {
      if (this.MouseDrag) this.MouseDrag.history = []
      clearInterval(this.animationTimer)
      delete this.animationTimer
    }
  }

  chart.JSChartContainer.animateMove = function (speed, duration = 1000) {
    this.cancelAnimation()

    const interval = 50
    const start = Date.now()
    const bezier = new Bezier(0.17, 0.89, 0.45, 1)
    const totalMove = speed * duration / 2
    let lastElapsed = 0
    this.animationTimer = setInterval(() => {
      if (Date.now() - start > duration) return this.cancelAnimation()

      const elapsed = (Date.now() - start) / duration
      const step = (bezier(elapsed) - bezier(lastElapsed)) * totalMove
      lastElapsed = elapsed

      const { Frame: { SubFrame: [ { Frame }] } } = this
      const width = Frame.GetFrameWidth()
      const pointWidth = width / Frame.XPointCount
      
      const maxOffset = Frame.Data.Data.length - Math.round(Frame.XPointCount / 4 * 3) // + this.RightSpaceCount
      const offset = Math.max(0, Math.min(maxOffset, Math.round(Frame.Data.DataOffset + step / pointWidth)))
      if (offset === Frame.Data.DataOffset) return this.cancelAnimation()

      Frame.Data.DataOffset = offset
      this.UpdataDataoffset()
      this.UpdatePointByCursorIndex(2)
      this.UpdateFrameMaxMin()
      this.ResetFrameXYSplit()
      this.Draw()
    }, interval)
  }

  chart.JSChartContainer.OnMouseMove = function(x, y, e, isPhone) {
    if (!this.MouseDrag) OnMouseMove.call(this, x, y, e, isPhone)
  }

  chart.JSChartContainer.OnTouchStart = function (e) {
    this.cancelAnimation()
    OnTouchStart.call(this, e)
  }

  chart.JSChartContainer.OnTouchEnd = function (e) {
    OnTouchEnd.call(this, e)
    this.LastPoint.X = null
    this.LastPoint.Y = null
    this.Draw()

    if (this.MouseDrag && this.MouseDrag.history.length > 2 && !this.PhonePinch) {
      const { history } = this.MouseDrag
      const first = history[0];
      const last = history[history.length - 1]
      const speed = (first.X - last.X) / (Date.now() - first.ts);
      this.animateMove(speed, 1000);
    }
  }

  chart.JSChartContainer.ZoomToPinch = function () {
    const { Frame: { SubFrame: [ { Frame }] } } = this
    const phonePinch = this.PhonePinch
    if (!phonePinch.Start.XPointCount) {
      phonePinch.Start.Center = Frame.Data.DataOffset + (Frame.XPointCount - 1) / 2
      phonePinch.Start.XPointCount = Frame.XPointCount
    }

    const start = Math.max(20, Math.abs(phonePinch.Start.X - phonePinch.Start.X2))
    const end = Math.max(20, Math.abs(phonePinch.Last.X - phonePinch.Last.X2))
    let newCount = Math.round(phonePinch.Start.XPointCount * start / end)
    if ((newCount - phonePinch.Start.XPointCount) % 2) return
    newCount = Math.min(Frame.Data.Data.length, newCount)
    if (newCount === Frame.LastCalculateStatus.XPointCount) return

    const width = Frame.GetFrameWidth()
    const pointWidth = width / newCount
    if (pointWidth < 3 || pointWidth > 180) return 

    Frame.LastCalculateStatus.XPointCount = Frame.XPointCount = newCount
    const maxOffset = Frame.Data.Data.length - Math.round(Frame.XPointCount / 4 * 3) // + this.RightSpaceCount
    Frame.Data.DataOffset = Math.max(0, Math.min(maxOffset, Math.round(phonePinch.Start.Center - (Frame.XPointCount - 1) / 2)))

    Frame.DataWidth = Math.round(pointWidth * 0.7)
    Frame.DistanceWidth = pointWidth - Frame.DataWidth
    this.Frame.UpdateAllFrame()
    this.UpdatePointByCursorIndex(2)
    this.UpdataDataoffset()
    this.UpdateFrameMaxMin()
    this.Draw()
    this.ShowTooltipByKeyDown()
    this.StopDragTimer()
  }

  chart.JSChartContainer.DragMove = function() {
    if (this.PhonePinch) return

    try {
      const width = this.Frame.SubFrame[0].Frame.GetFrameWidth();
      const { Frame: { SubFrame: [ { Frame: { XPointCount, Data } }] } } = this
      const drag = this.MouseDrag
      if (!drag || !Data || !XPointCount) return
      if (!drag.Click.DataOffset) drag.Click.DataOffset = Data.DataOffset

      const moved = Math.round((drag.Click.X - drag.LastMove.X) * XPointCount / width)
      if (moved) {
        const max = Data.Data.length - Math.round(XPointCount / 4 * 3) // + this.RightSpaceCount
        Data.DataOffset = Math.max(0, Math.min(max, drag.Click.DataOffset + moved))
        if (Data.DataOffset < 100) chart.loadMore()
        return true
      }      
    } catch (e) {
      console.error(e)
    }
  }

  chart.JSChartContainer.OnTouchMove = function(e) {
    var touches = this.GetToucheData(e, this.IsForceLandscape);
    if (this.IsPhoneDragging(e)) {
      var drag = this.MouseDrag;
      if (drag == null) {
        if (this.IsForceLandscape) {
          /*const y =*/ this.UIElement.getBoundingClientRect().width - touches[0].clientY; //强制横屏Y计算
        }

        if (!this.ChartDrawOption.IsLockScreen)
          this.MoveCorssCursor(
            { X: touches[0].clientX, Y: touches[0].clientY },
            e
          );
      } else {
        this.recordDrag();

        var moveAngle = this.GetMoveAngle(drag.LastMove, {
          X: touches[0].clientX,
          Y: touches[0].clientY,
        });
        var moveSetp = Math.abs(drag.LastMove.X - touches[0].clientX);
        var moveUpDown = Math.abs(drag.LastMove.Y - touches[0].clientY);
        moveSetp = parseInt(moveSetp);
        var isMoveCorssCursor =
          this.DragMode == JSCHART_DRAG_ID.CLICK_TOUCH_MODE_ID &&
          this.TouchStatus.CorssCursorShow == true; //是否移动十字光标
        //JSConsole.Chart.Log("[JSChartContainer::uielement.ontouchmove]  moveAngle , moveUpDown", moveAngle,moveUpDown);
        if (this.CurrentChartDrawPicture) {
          var drawPicture = this.CurrentChartDrawPicture;
          if (drawPicture.Status == 1 || drawPicture.Status == 2) {
            if (moveSetp < 5 && moveUpDown < 5) return;
            if (
              this.SetChartDrawPictureSecondPoint(
                touches[0].clientX,
                touches[0].clientY,
                true
              )
            ) {
              this.DrawDynamicInfo();
            }
          } else if (drawPicture.Status == 3) {
            if (
              this.SetChartDrawPictureThirdPoint(
                touches[0].clientX,
                touches[0].clientY,
                true
              )
            ) {
              this.DrawDynamicInfo();
            }
          } else if (drawPicture.Status == 20) {
            //画图工具移动
            if (moveSetp < 5 && moveUpDown < 5) return;
            if (
              this.MoveChartDrawPicture(
                touches[0].clientX - drag.LastMove.X,
                touches[0].clientY - drag.LastMove.Y,
                true
              )
            ) {
              this.DrawDynamicInfo();
            }
          }
          drag.LastMove.X = touches[0].clientX;
          drag.LastMove.Y = touches[0].clientY;
        } else if (isMoveCorssCursor) {
          //点击模式下 十字光标显示 左右移动十字光标
          // var mouseDrag = this.MouseDrag;
          this.MouseDrag = null;
          this.MoveCorssCursor(drag.Click, e); //移动十字光标
        } else if (this.DragMode == 1 || isMoveCorssCursor == false) {
          //数据左右拖拽
          if (
            ((moveUpDown > 0 && moveSetp <= 3) ||
              moveAngle <= this.TouchMoveMinAngle) &&
            this.EnableScrollUpDown == true
          ) {
            this.StopDragTimer();
            return;
          }

          drag.LastMove.X = touches[0].clientX;
          drag.LastMove.Y = touches[0].clientY;
          if (this.DragMove()) {
            this.UpdataDataoffset();
            this.UpdatePointByCursorIndex(2);
            this.UpdateFrameMaxMin();
            this.ResetFrameXYSplit();
            this.Draw();
          } else {
            if (this.DragDownloadData) this.DragDownloadData();
          }
        }
      }
      this.PhoneTouchInfo.End.X = touches[0].clientX;
      this.PhoneTouchInfo.End.Y = touches[0].clientY;
    } else if (this.IsPhonePinching(e)) {
      if (this.DragMode == JSCHART_DRAG_ID.DISABLE_DRAG_ID) return;
      this.PreventTouchEvent(e);
      var phonePinch = this.PhonePinch;
      if (!phonePinch) return;
      if (this.EnableZoomUpDown && this.EnableZoomUpDown.Touch === false)
        return;

      phonePinch.Last = {
        X: touches[0].pageX,
        Y: touches[0].pageY,
        X2: touches[1].pageX,
        Y2: touches[1].pageY,
      };

      this.ZoomToPinch();
    }

    this.PreventTouchEvent(e);
  };

  chart.JSChartContainer.DocOnMouseMove = function(e) {
    //加载数据中,禁用鼠标事件
    if (this.ChartSplashPaint && this.IsEnableSplash == true) return;
    var drag = this.MouseDrag;
    if (!drag) return;
    var moveSetp = Math.abs(drag.LastMove.X - e.clientX);
    if (this.BorderDrag && this.BorderDrag.Index >= 0) {
      if (Math.abs(drag.LastMove.Y - e.clientY) < 5) return;
      const yMove = e.clientY - drag.LastMove.Y;
      this.OnMoveFromeBorder(this.BorderDrag.Index, yMove);
      drag.LastMove.X = e.clientX;
      drag.LastMove.Y = e.clientY;
    } else if (this.YDrag && this.YDrag.Index >= 0) {
      if (Math.abs(drag.LastMove.Y - e.clientY) < 5) return;
      const yMove = e.clientY - drag.LastMove.Y; //this.UIElement.style.cursor="n-resize";
      JSConsole.Chart.Log(
        "[JSChartContainer::DocOnMouseMove] ",
        this.YDrag,
        yMove
      );
      this.OnZoomUpDownFrameY(this.YDrag, yMove);
      drag.LastMove.X = e.clientX;
      drag.LastMove.Y = e.clientY;
    } else if (this.CurrentChartDrawPicture) {
      var drawPicture = this.CurrentChartDrawPicture;
      if (drawPicture.Status == 1 || drawPicture.Status == 2) {
        if (
          Math.abs(drag.LastMove.X - e.clientX) < 5 &&
          Math.abs(drag.LastMove.Y - e.clientY) < 5
        )
          return;
        if (this.SetChartDrawPictureSecondPoint(e.clientX, e.clientY)) {
          this.DrawDynamicInfo();
        }
      } else if (drawPicture.Status == 3) {
        if (this.SetChartDrawPictureThirdPoint(e.clientX, e.clientY)) {
          this.DrawDynamicInfo();
        }
      } else if (drawPicture.Status == 20) {
        //画图工具移动
        if (
          Math.abs(drag.LastMove.X - e.clientX) < 5 &&
          Math.abs(drag.LastMove.Y - e.clientY) < 5
        )
          return;
        if (
          this.MoveChartDrawPicture(
            e.clientX - drag.LastMove.X,
            e.clientY - drag.LastMove.Y
          )
        ) {
          this.DrawDynamicInfo();
        }
      }
      drag.LastMove.X = e.clientX;
      drag.LastMove.Y = e.clientY;
    } else if (this.DragMode == 1) {
      //数据左右拖拽
      if (moveSetp < 5) return;
      var isLeft = true;
      if (drag.LastMove.X < e.clientX) isLeft = false; //右移数据
      this.UIElement.style.cursor = "pointer";
      if (this.DataMove(moveSetp, isLeft)) {
        this.UpdataDataoffset();
        this.UpdatePointByCursorIndex(2);
        this.UpdateFrameMaxMin();
        this.ResetFrameXYSplit();
        this.Draw();
      } else {
        if (this.DragDownloadData) this.DragDownloadData();
      }
      drag.LastMove.X = e.clientX;
      drag.LastMove.Y = e.clientY;
    } else if (this.DragMode == 2) {
      //区间选择
      var yMoveSetp = Math.abs(drag.LastMove.Y - e.clientY);
      if (moveSetp < 5 && yMoveSetp < 5) return;
      var x = drag.Click.X - this.UIElement.getBoundingClientRect().left;
      const y = drag.Click.Y - this.UIElement.getBoundingClientRect().top;
      var x2 = e.clientX - this.UIElement.getBoundingClientRect().left;
      var y2 = e.clientY - this.UIElement.getBoundingClientRect().top;
      this.ShowSelectRect(x, y, x2, y2);
      drag.LastMove.X = e.clientX;
      drag.LastMove.Y = e.clientY;
    }
  };
  // chart.JSChartContainer.Frame.ZoomUp(88);
}