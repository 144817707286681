<template>
<div class="weui-cell weui-cell_active weui-cell_access weui-cell_select weui-cell_select-after">
  <div class="weui-cell__hd close"><button class="weui-icon-btn" @click="showChart = false"><i class="weui-icon-close-thin" /></button></div>
  <div class="weui-cell__bd title">{{currentSymbol.name}}</div>
  <div class="weui-cell__bd picker" @click="showPicker">
    {{modelValue.period[1]}}
    <span v-if="modelValue.period[0] !== 'min'">{{modelValue.indexes[0]}} {{modelValue.indexes[1]}}</span>
    <span v-if="modelValue.period[0] === 'min'">{{modelValue.indexes[2]}}</span>
  </div>
</div>
</template>

<script>
import { defineComponent, inject } from 'vue'
import weui from 'weui.js'

export default defineComponent({
  props: {
    modelValue: Object
  },
  setup(props, { emit }) {
    const showChart = inject('showChart')
    const currentSymbol = inject('currentSymbol', { name: '' })
    const periods = { min: '分时', min1: '1分钟', min5: '5分钟', min15: '15分钟', min30: '30分钟', min60: '小时线', day: '日线', week: '周线', month: '月线' }
    const idexesForMin = ['MACD', /*'量比',*/ 'DMI', 'DMA', 'BRAR', 'KDJ', 'RSI', 'WR', 'CCI', 'TRIX', /*'涨跌趋势'*/]
    const overlays = ['BBI', 'MA', 'HMA', 'LMA', 'VMA', 'BOLL']
    const oscillators = ['SKDJ', 'KDJ', 'MACD', 'RSI', 'OBV', 'BIAS']

    return {
      showChart, currentSymbol,
      showPicker() {
        weui.picker(Object.keys(periods).map(value => ({
          label: periods[value], value,
          children: value === 'min' ?
            idexesForMin.map(i => ({ label: i, value: i })) :
            overlays.map(i => ({ label: i, value: i, children: oscillators.map(i => ({ label: i, value: i })) }))
        })), {
          id: 'zq-index',
          depth: 3,
          defaultValue: [props.modelValue.period[0], ...props.modelValue.indexes],
            onConfirm: function (result) {
              const indexes = result.slice(1).map(r => r.value)
              emit('update:modelValue', {
                period: [result[0].value, result[0].label],
                indexes: result[0].value === 'min' ? [...props.modelValue.indexes.slice(0, 2), indexes[0]] : [...indexes, props.modelValue.indexes[2]]
              })
            },
            title: '选择周期&指标'
        })
      }
    }
  }
})
</script>

<style scoped>
.title {
  font-weight: bold;
  text-align: center;
}
.picker {
  text-align: right;
  padding-right: 35px;
  flex-grow: 0;
  white-space: nowrap;
}

@media screen and (min-width: 720px) {
  .close {
    display: none;
  }
}
</style>
