import axios from 'axios'
import jsonp from 'axios-jsonp'
import moment from 'moment'
import HQChart from 'hqchart'

function restoreData(arr, digits) {
  for (let i = 1; i < arr.length; i++) {
    arr[i] += arr[i - 1]
  }

  const factor = 10 ** digits
  for (let i = 0; i < arr.length; i++) {
    arr[i] /= factor
  }
}

const symbolDigits = new Map()
HQChart.Chart.MARKET_SUFFIX_NAME.GetCustomDecimal = symbol => {
  symbol.replace('.FOREX', '')
  return symbolDigits.get(symbol) || 4
}

export async function getHistoryData(symbol, zq, count = 1440 * 3, se = undefined) {
  symbol = symbol.replace(/\..+/g, '')
  const { data: [, [digits, ts, open, high, low, close, vol, amount]] } = await axios({
    url: 'http://60.205.205.126:81/chartdata',
    adapter: jsonp,
    params: { callback: '', symbol, zq: zq, n: count, ...se }
  })

  symbolDigits.set(symbol, digits)

  restoreData(ts, -3)
  restoreData(open, digits)
  restoreData(high, digits)
  restoreData(low, digits)
  restoreData(close, digits)

  const shouldFormatTime = ['day', 'week', 'month'].indexOf(zq) < 0

  if (se && se.e) {
    return {
      code: 0,
      count: 1,
      ver: 2,
      firstTs: ts[0],
      lastTs: ts[ts.length - 1],
      stock: ts.map((date, idx) => ({
        time: parseInt(moment(date).format('HHmmss')),
        date: parseInt(moment(ts[ts.length - 1]).format('YYYYMMDD')),
        price: close[idx], // ???
        open: open[idx],
        high: high[idx],
        yclose: null,
        low: low[idx],
        amount: amount[idx],
        vol: vol[idx],
        symbol: symbol + '.FOREX',
        name: ''
      })),
      data: ts.map((date, idx) => [
        parseInt(moment(date).format('YYYYMMDD')), idx === 0 ? null : close[idx - 1], open[idx], high[idx], low[idx], close[idx], vol[idx], amount[idx], shouldFormatTime ? parseInt(moment(date).format('HHmm')) : null,
      ])
    }
  } if (zq !== 'min') {
    return {
      code: 0,
      symbol: symbol + '.FOREX',
      name: '',
      firstTs: ts[0],
      lastTs: ts[ts.length - 1],
      data: ts.map((date, idx) => [
        parseInt(moment(date).format('YYYYMMDD')), idx === 0 ? null : close[idx - 1], open[idx], high[idx], low[idx], close[idx], vol[idx], amount[idx], shouldFormatTime ? parseInt(moment(date).format('HHmm')) : null,
      ])
    }
  } else {
    return {
      code: 0,
      count: 1,
      firstTs: ts[0],
      lastTs: ts[ts.length - 1],
      stock: [{
        amount: amount.reduce((a, b) => a + b),
        date: parseInt(moment(ts[ts.length - 1]).format('YYYYMMDD')),
        high: Math.max(...high),
        low: Math.min(...low),
        open: open[0],
        price: close[close.length - 1],
        symbol: symbol + '.FOREX',
        time: parseInt(moment(ts[ts.length - 1]).format('HHmm')),
        vol: vol.reduce((a, b) => a + b),
        yclose: open[0],
        minute: ts.map((date, idx) => ({
          amount: amount[idx],
          avprice: close[idx], // ???
          high: high[idx],
          increase: null, // ???
          low: low[idx],
          open: open[idx],
          price: close[idx], // ???
          risefall: null, // ???
          time: parseInt(moment(date).format('HHmm')),
          vol: vol[idx]
        }))
      }]
    }

    // return { code: 0, symbol, name: '', data }
  }
}