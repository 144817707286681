<template>
<table class="quotation">
  <thead>
    <tr><th>名称</th><th style="width: 20px;"></th><th>现价</th><th>涨跌幅</th></tr>
  </thead>
  <tbody>
    <tr v-for="q in quotations.filter(q => q.price)" :key="q.name" @click="setCurrentSymbol(q)">
      <td>{{q.name}}</td>
      <td :class="{ red: q.updown > 0, green: q.updown < 0 }">{{getUpdown(q)}}</td>
      <td :class="{ red: q.fluctuation > 0, green: q.fluctuation < 0 }">{{q.price.toFixed(q.digits)}}</td>
      <td :class="{ red: q.fluctuation > 0, green: q.fluctuation < 0 }">{{(q.fluctuationRate * 100).toFixed(2)}}%</td>
    </tr>
  </tbody>
</table>
</template>

<script>
import { defineComponent, inject, watch } from 'vue'
import axios from 'axios'
import jsonp from 'axios-jsonp'

import { quotations, fields, symbols, init, registerSymbols } from '@/api/quotation'

export default defineComponent({
  setup() {
    init()
    const currentMarket = inject('currentMarket', { name: '' })
    const currentSymbol = inject('currentSymbol')
    const showChart = inject('showChart')

    watch(currentMarket, async (m, o) => {
      if (m.id && m.id !== o.id) {
        const { data: { symbols } } = await axios({
          url: 'http://m.ytcj.com:8080/action/market/get_symbols',
          adapter: jsonp,
          params: { market: m.id, page: 0, page_size: 20 }
        })

        registerSymbols({ symbols: symbols.map(s => s.id) })
        currentSymbol.value = { name: '' }
      }
    })

    watch(quotations, q => {
      if (q) q = q.filter(q => q.price > 0)
      if (q && q.length > 0 && !currentSymbol.value.symbol) {
        const { symbol, name } = q[0]
        currentSymbol.value = { symbol, name }
      }
    })

    return {
      currentMarket, quotations, fields, symbols,
      getUpdown({ updown: v }) {
        return v > 0 ? '↑' : v < 0 ? '↓' : ''
      },
      setCurrentSymbol({ symbol, name }) {
        showChart.value = true
        currentSymbol.value = { symbol, name }
      }
    }
  }
})
</script>

<style scoped lang="scss">
table.quotation {
  border-spacing: 0;
  border-collapse: separate;
  width: 100%;

  tbody tr:hover {
    background-color: #eee;
  }

  th, td {
    border-bottom: 1px solid #ddd;
    text-align: left;
    padding: 12px 0;

    text-overflow: unset;
    white-space: nowrap;

    &.red {
      color: red;
    }

    &.green {
      color: green!important;
    }
  }

  th {
    padding-top: 0;
  }
}
</style>