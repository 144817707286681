<template>
  <div ref="container" />
</template>

<script>
// import moment from 'moment-timezone'
import ResizeSensor from 'css-element-queries/src/ResizeSensor'
import { defineComponent, onMounted, watch, ref } from 'vue'

import HQChart from 'hqchart'
HQChart.Chart.JSConsole.Chart.Log = () => undefined
HQChart.Chart.JSConsole.Complier.Log = () => undefined

import wrapKLine from './wrapKLine'
import { getHistoryData } from '@/api/chartData'

const zqToPeriod = { min: 4, min1: 4, min5: 5, min15: 6, min30: 7, min60: 8, day: 0, week: 1, month: 2 }

let running = false
let lastTs = 0
let firstTs = 0

export default defineComponent({
  props: {
    symbol: String,
    zq: { type: String, required: true },
    overlay: String,
    oscillator: String
  },
  setup(props) {
    let chart
    const container = ref()

    onMounted(() => {
      new ResizeSensor(container.value.parentNode, function() {
        if (chart) {
          container.value.style.width = container.value.parentNode.clientWidth + 'px'
          container.value.style.height = container.value.parentNode.clientHeight + 'px'
          chart.OnSize()
        }
      })

      container.value.style.width = container.value.parentNode.clientWidth + 'px'
      container.value.style.height = container.value.parentNode.clientHeight + 'px'
      chart = HQChart.Chart.JSChart.Init(container.value)

      chart.loadMore = async () => {
        if (props.symbol && firstTs) {
          if (running) return

          running = true
          const data = await getHistoryData(props.symbol, props.zq, 1440, { s: firstTs })
          firstTs = Math.floor(data.firstTs / 1000)

          if (data.data.length > 1) {
            chart.JSChartContainer.RecvHistoryMinutePageData(data)
          } else {
            firstTs = 0
          }
          // if (['day', 'week', 'month'].indexOf(props.zq) < 0) chart.JSChartContainer.RecvHistoryMinutePageData(data)
          // else chart.JSChartContainer.RecvHistoryMinutePageData(data)
          running = false
        }
      }

      async function getKlineData(data, callback) {
        data.PreventDefault = true
        switch(data.Name) {
          case 'KLineChartContainer::ReqeustHistoryMinuteData':
          case 'KLineChartContainer::RequestHistoryData':         //日线全量数据下载
            data = await getHistoryData(data.Request.Data.symbol, props.zq)
            lastTs = Math.floor(data.lastTs / 1000)
            firstTs = Math.floor(data.firstTs / 1000)
            callback(data)
            break;
          case 'KLineChartContainer::RequestMinuteRealtimeData':
          case 'KLineChartContainer::RequestRealtimeData':
            data = await getHistoryData(data.Request.Data.symbol[0], props.zq, 8, { e: lastTs })
            if (data.lastTs) lastTs = Math.floor(data.lastTs / 1000)
            callback(data)
            break;
          case 'KLineChartContainer::RequestFlowCapitalData':             //流通股本
            data.PreventDefault = false
            break;
        }
      }

      chart.SetOption({
        Type: '历史K线图',
        Symbol: props.symbol + '.FOREX',
        NetworkFilter: getKlineData,
        Windows: [
          { Index: props.overlay },
          { Index: 'VOL' },
          { Index: props.oscillator }
        ], //窗口指标
        IsAutoUpdate: true, //是自动更新数据
        AutoUpdateFrequency: 1000,
        IsShowRightMenu: false, //右键菜单
        IsApiPeriod: true,
        CorssCursorInfo:{ DateFormatType: 2 },
        // CorssCursorTouchEnd: true,
        KLine: {
          DragMode: 1, //拖拽模式 0 禁止拖拽 1 数据拖拽 2 区间选择
          Right: 1, //复权 0 不复权 1 前复权 2 后复权
          Period: zqToPeriod[props.zq || ''], //周期 0 日线 1 周线 2 月线 3 年线
          MaxReqeustDataCount: 3000, //日线数据最近1000天
          MaxRequestMinuteDayCount: 15,    //分钟数据最近15天
          PageSize: Math.floor(container.value.parentNode.clientWidth / 10), //一屏显示多少数据
          IsShowTooltip: false, //是否显示K线提示信息
          RightSpaceCount:2,  //右边预留2个K线宽度空白
          ZoomType:1,         //PC页面版 缩放以十字光标为中心两边缩放
          DataWidth:10,
        },
        KLineTitle: {        
          IsShowName: false, //不显示股票名称
          IsShowSettingInfo: false //不显示周期/复权    
        },
        Border: {
          Left: 1, //左边间距
          Right: 60, //右边间距
          Top: 25
        },
        Frame: [
          { SplitCount: 5, StringFormat: 0, IsShowLeftText: false },
          { SplitCount: 5, StringFormat: 0, IsShowLeftText: false ,EnableRemoveZero:true, MinYDistance:20 },
          { SplitCount: 5, StringFormat: 0, IsShowLeftText: false ,EnableRemoveZero:true},
          { SplitCount: 5, StringFormat: 0, IsShowLeftText: false ,EnableRemoveZero:true}
        ]
      })

      wrapKLine(chart)
    })

    watch(() => ([props.symbol, props.zq]), ([symbol, zq], [oldSymbol, oldZq]) => {
      if (oldSymbol !== symbol && zq !== 'min') {
        chart.ChangeSymbol(symbol + '.FOREX')
      }

      if (zq !== oldZq && zq !== 'min') {
        chart.ChangePeriod(zqToPeriod[zq || ''])
      }
    })

    watch(() => ([props.overlay, props.oscillator]), ([overlay, oscillator]) => {
      chart.ChangeIndex(0, overlay)
      chart.ChangeIndex(2, oscillator)
    })

    return { container }
  }
})
</script>
